import Image from 'next/image';
import Link from 'next/link';

export default function OfferList({ items = [] }) {
  return (
    <section className="grid gap-5 md:grid-cols-2 lg:grid-cols-3">
      {items.map((item, k) => (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {item.thumbnail.length > 0 && (
            <article className="flex flex-col shadow-lg overflow-hidden">
              <Link
                href={item.link}
                key={k}
              >
                <a>
                  <div
                    className="relative w-full h-[200px]"
                  >
                    <Image
                      src={item.thumbnail[0].publicUrl}
                      alt={item.thumbnail[0]?.properties?.alternative ?? ''}
                      layout="fill"
                      objectFit="cover"
                    />
                  </div>
                  <div className="p-6 bg-white">
                    <h2 className="text-xl font-semibold text-gray-900">{item.title}</h2>

                    {item?.abstract?.length > 0 && (
                    <div
                      className="mt-3 text-base text-gray-500 mt-2"
                      dangerouslySetInnerHTML={{ __html: item.abstract }}
                    />
                    )}
                  </div>
                </a>
              </Link>
            </article>

          )}
        </>
      ))}
    </section>
  );
}
