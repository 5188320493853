import Image from 'next/image';
import parse from '@/components/helper/image';
import Link from '@/components/helper/link';
import PaginationAlternative from '@/components/elements/pagination-alternative';

function readingTime(text) {
  const wpm = 225;
  const words = text.trim().split(/\s+/).length;

  return Math.ceil(words / wpm);
}

export default function NewsList({ item, page }) {
  return (
    <div>
      <PaginationAlternative pagination={item.content.data.pagination} position="top" />
      <div className="grid gap-5 lg:grid-cols-3">
        {item.content.data.list.map((post) => (
          <div key={post.title} className="flex flex-col shadow-lg overflow-hidden">
            <Link href={`${post.slug}`}>
              <a className="flex-shrink-0">
                {post.media.length > 0 && (
                  <Image
                    height="300"
                    width="500"
                    src={parse(post.media[0].images.defaultImage.publicUrl)}
                    objectFit="cover"
                    alt={post.media[0].properties.alternative}
                    title={post.media[0].properties.title}
                  />
                )}

              </a>
            </Link>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <p className="text-sm font-medium text-primary-600">
                  {post.categories.length > 0 ? (
                    <Link href={`${page.slug}/${post.categories[0].slug}`}>
                      <a className="hover:underline">
                        {post.categories[0].title}
                      </a>
                    </Link>
                  ) : (<span>-</span>)}
                </p>
                <Link href={`${post.slug}`}>
                  <a className="block mt-2">
                    <h2 className="text-xl font-semibold text-gray-900">{post.title}</h2>
                    <p className="mt-3 text-base text-gray-500">{post.teaser}</p>
                  </a>
                </Link>
              </div>
              <div className="mt-6 flex items-center">
                <div className="flex-shrink-0">
                  <div>
                    <span className="sr-only">{post.author.author}</span>
                    <Image
                      height={50}
                      width={50}
                      className="rounded-full"
                      src="/icons/favicon.svg"
                      alt={post.title}
                      title={post.title}
                    />
                  </div>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">
                    <span className="_hover:underline">
                      {post.author.author}
                    </span>
                  </p>
                  <div className="flex space-x-1 text-sm text-gray-500">
                    <time dateTime={post.created_at}>{post.crdate}</time>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <PaginationAlternative pagination={item.content.data.pagination} />
    </div>
  );
}
