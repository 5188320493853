import Image from 'next/image';
import ContentHeadline from './headline';
import Button from '../elements/button';
import parse from '../helper/image';

function buildGallery(galleryObject = {}) {
  const gallery = [];

  const rows = Array.isArray(galleryObject.rows) ? galleryObject.rows : Object.values(galleryObject.rows || {});

  Object.values(rows).forEach((i) => {
    Object.values(i.columns).flat(1).forEach((i) => {
      gallery.push(i);
    });
  });

  return gallery;
}

export default function Banner({
  item,
  cols = 1,
}) {
  const gallery = buildGallery(item.content.gallery || {});
  let image = gallery.length > 0 ? gallery[0] : null;

  if (item.appearance.backgroundImage && item.appearance.backgroundImage.length) {
    [image] = item.appearance.backgroundImage;
  }

  return (
    <div className="relative">
      <div className="grid grid-cols-5 md:absolute md:inset-0 z-0">
        <div
          className={`col-span-5 relative h-40 md:h-auto ${cols === 1 ? 'md:col-span-2 lg:col-span-3' : 'md:col-span-1 lg:col-span-2'}`}
        >
          {image && (
            <Image
              src={parse(image.publicUrl)}
              alt={image.properties.alternative}
              title={image.properties.title}
              layout="fill"
              objectFit="cover"
            />
          )}
        </div>
        <div
          className={`hidden md:block bg-gray-100 ${cols === 1 ? 'md:col-span-3 lg:col-span-2' : 'md:col-span-4 lg:col-span-3'}`}
        />
      </div>

      <div
        className="max-w-7xl mx-auto w-full grid md:grid-cols-5 grid-cols-1 md:gap-5 lg:gap-8 relative"
      >
        <div className={`${cols === 1 ? 'md:col-span-2 lg:col-span-3' : 'md:col-span-1 lg:col-span-2'}`} />
        <div
          className={`bg-gray-100 ${cols === 1 ? 'px-10 py-10 md:col-span-3 lg:col-span-2' : 'px-5 py-10 md:col-span-4 lg:col-span-3'}`}
        >
          <ContentHeadline
            after={item.content.suffix}
            before={item.content.prefix}
            tag={item.content.headerLayout || 2}
          >
            {item.content.header}
          </ContentHeadline>
          <div
            className="mb-5 lg:columns-2 prose text-justify"
            dangerouslySetInnerHTML={{ __html: item.content.bodytext }}
          />
          {item.content.headerLink && (
            <Button href={item.content.headerLink.href}>{item.content.headerLink.title || 'Mehr zum Thema'}</Button>
          )}
        </div>
      </div>
    </div>
  );
}
