import { useEffect, useRef, useState } from 'react';

export default function Slider({ items }) {
  const ref = useRef(null);
  const [siteWidth, setSiteWidth] = useState(480);
  const [maxWidth, setMaxWidth] = useState(380);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [itemWidth, setItemWidth] = useState(150);

  useEffect(() => {
    if (ref.current) {
      const amount = ref.current.children.length;

      setSiteWidth(ref.current.clientWidth);
      setMaxWidth(amount * ref.current.firstChild.clientWidth + 12);
      setItemWidth(ref.current.firstChild.clientWidth + 16);
    }
  }, [ref.current]);

  const next = () => {
    if (currentOffset < maxWidth - siteWidth) {
      setCurrentOffset(currentOffset + itemWidth);
    } else {
      setCurrentOffset(0);
    }
  };

  const prev = () => {
    if (currentOffset > 0) {
      setCurrentOffset(currentOffset - itemWidth);
    }
  };

  return (
    <div className="relative">
      <div className="absolute right-0 -top-24 pt-10 pb-1 space-x-8 lg:flex hidden lg:visible">
        <button
          className="bg-transparent border-none appearance-none"
          onClick={prev}
        >
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 7 12"
            className={`${currentOffset > 0 ? 'text-primary-500' : 'text-gray-300'} w-6 h-6`}
          >
            <path
              d="M5.962.482L1.218 5.226l-.431.431c-.026.026-.178.127-.178.178-.025.127.38.431.457.507.913.965 1.826 1.929 2.765 2.867.71.761 1.446 1.497 2.156 2.258.254.254-.152.66-.405.406C3.983 10.2 2.385 8.524.762 6.85.583 6.647.38 6.47.202 6.266c-.38-.43-.025-.786.254-1.09L3.222 2.41 5.556.076c.254-.254.66.152.406.406z"
              fill="currentColor"
            />
          </svg>
        </button>
        <button
          className="bg-transparent border-none appearance-none"
          onClick={next}
        >
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 6 12"
            className="text-primary-500 w-6 h-6"
          >
            <path
              d="M.076.482L4.82 5.226l.431.431c.026.026.178.127.178.178.025.127-.38.431-.457.507-.913.965-1.826 1.929-2.765 2.867-.71.761-1.446 1.497-2.156 2.258-.254.254.152.66.406.406C2.055 10.2 3.653 8.524 5.277 6.85c.177-.203.38-.38.558-.584.38-.43.025-.786-.254-1.09L2.816 2.41.482.076C.228-.178-.178.228.076.482z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>
      <div className=" lg:overflow-hidden">
        <ul
          ref={ref}
          style={{ transform: `translateX(${-currentOffset}px)` }}
          className="grid grid-flow-col justify-items-start grid-rows-1 gap-4 overflow-y-hidden overflow-x-auto lg:overflow-visible transition-transform duration-500 ease-in-out"
        >
          {items.map((item, k) => (
            <li
              key={k}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
