import { PlusIcon } from '@heroicons/react/solid';

export default function Features({ items }) {
  return (
    <div
      className="grid lg:grid-cols-4 md:grid-cols-2 gap-2 w-full"
    >
      {items.map((v) => (
        <div className="flex" key={v.header}>
          <div className="pt-3 pr-3">
            <div className="w-8">
              <PlusIcon className="h-8 text-action" />
            </div>
          </div>
          <div>
            <h1 className="font-semibold py-3 text-xl">
              {v.header}
            </h1>
            <div className="lg:text-base text-sm font-extralight" dangerouslySetInnerHTML={{ __html: v.bodytext }} />
          </div>
        </div>
      ))}
    </div>
  );
}
