import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { fetchAPI as connectApi } from '@/lib/connect';

import SEO from '@/components/helper/seo';
import Hero from '@/components/content/hero';
import Search from '@/components/search';
import { RenderElements } from '@/components/content';
import Breadcrumbs from '@/components/globals/breadcrumbs';
import moment from 'moment';
import appConfig from '../../../app.config';
import defaultDate from '../../utils/defaultDate';

export const getServerSideProps = async ({
  page,
  locale,
  params,
  query,
  serverSideTranslations,
}) => {
  const isTypeSearch = Object.keys(page.filter).length > 0;

  let filter = {};
  let search = {
    data: [],
    meta: {
      total: 0,
      to: 1,
      from: 1,
    },
  };

  if (isTypeSearch) {
    filter = {
      group: 'model',
      typ: 'w',
      duration: 7,
      page: 1,
      channel: appConfig.channel,
      sort: 'date_from',
      ...page?.filter,
      ...query,
    };

    if (!filter.date_from) {
      filter.date_from = defaultDate();
      filter.date_to = moment(filter.date_from).add(3, 'weeks').format('YYYY-MM-DD');
    }

    // filter = omit('slug', filter);
    search = await connectApi('/search', filter);
  }

  return {
    props: {
      page,
      search,
      filter,
      locale,
      ...await serverSideTranslations(locale, ['boat', 'common', 'footer']),
    },
    // revalidate: 10, // In seconds
  };
};

export default function Page({
  page, search, filter,
}) {
  const [checked, setChecked] = useState(false);
  const items = Object.values(page.content).flat(1).filter((i) => i !== null);
  const showToggle = (!page.options || !page.options.hideToggle) && items.length > 0;
  const buttonLabel = (!page.options || !page.options.searchButton) ? 'Revierinformationen' : page.options.searchButton;
  const isTypeSearch = Object.keys(page.filter).length > 0;
  const [searchData, setSearchData] = useState(search?.data || []);
  const [searchMeta, setSearchMeta] = useState(search?.meta || {});

  useEffect(() => {
    setSearchData(search?.data || []);
    setSearchMeta(search?.meta || {});
  }, [search]);

  return (
    <div>
      <SEO
        title={page.meta.title}
        description={page.meta.description}
        canonicalUrl={page.meta.canonical?.href}
        image={page.meta.thumbnail}
      />

      <Hero
        title={page.meta.title}
        teaser={page.meta.abstract}
        pretitle={page.meta.subtitle}
        checked={checked}
        setChecked={setChecked}
        photoUrl={page.meta.thumbnail}
        switchLabel={buttonLabel}
        toggle={showToggle && isTypeSearch}
      />

      {isTypeSearch && (
        <Transition
          show={!checked}
          enter="transition-all ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100 "
          leave="transition-all ease-in duration-150"
          leaveFrom="opacity-100 "
          leaveTo="opacity-0"
        >
          <div className="-mt-24 md:-mt-30">
            <Search
              data={searchData}
              meta={searchMeta}
              filter={filter}
              setData={setSearchData}
              setMeta={setSearchMeta}
            />
          </div>
        </Transition>
      )}

      {!isTypeSearch && (
        <div className="mt-3" />
      )}

      <div
        className="max-w-7xl mx-auto w-full px-6 xl:px-0 mt-2"
      >
        <Breadcrumbs items={page.breadcrumbs} />
      </div>

      <RenderElements elements={items} page={page} />
    </div>
  );
}
