// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/bundle';
import { Navigation } from 'swiper';
import Image from 'next/image';

export default function Carousel({ item }) {
  return (
    <div className="max-w-3xl">
      {
        item.content.items.length > 0
        && (
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            breakpoints={{
              '@0.1': {
                slidesPerView: 1,
              },
              '@0.75': {
                slidesPerView: 1,
              },
              '@1.00': {
                slidesPerView: 1,
              },
            }}
            modules={[Navigation]}
            navigation
            className="w-full"
          >
            {item.content.items.map((slide, k) => (
              <SwiperSlide key={k}>
                <div className="relative block p-10">
                  {slide.backgroundImage[0]?.publicUrl !== undefined && (
                    <Image src={slide.backgroundImage[0].publicUrl} layout="fill" objectFit="cover" />
                  )}
                  <div className="block w-full max-w-lg bg-white bg-opacity-90 p-10 z-10 relative ">
                    <h3 className="text-4xl font-semibold text-primary mb-2">{slide.header}</h3>
                    <div className="text-lg" dangerouslySetInnerHTML={{ __html: slide.bodytext }} />
                    <button className="bg-primary text-white mt-4 py-2 px-3 text-2xl font-bold">{slide.buttonText}</button>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )
      }
    </div>
  );
}
