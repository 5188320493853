import { ChevronRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-scroll';

export default function TableOfContents({ items }) {
  if (!items.length) {
    return (<div />);
  }

  return (
    <div className="bg-gray-100 p-5">
      <p className="mb-3 text-xl text-primary ml-5">Themenbereiche</p>
      <ul className="grid grid-cols-1 grid-flow-row text-sm place-content-between divide-white divide-y">
        {items.map((i, k) => (
          <li key={k}>
            <Link
              className="flex py-1.5 hover:text-primary hover:font-semibold"
              to={`${i.id}`}
              smooth
              offset={-100}
              duration={500}
            >
              <ChevronRightIcon className="text-action h-4 mr-2 mt-0.5 flex-shrink-0" />
              {' '}
              {i.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
