import { Element } from 'react-scroll';
import CruiseSlider from '@/components/content/cruise-slider';
import BoatSlider from '@/components/content/boat-slider';
import FaqGrouped from '@/components/content/faq-grouped';
import NewsList from '@/components/content/news-list';
import OfferList from '@/components/content/offer-list';
import SwiperOfferSlider from '@/components/content/swiper-offer-slider';
import Carousel from '@/components/content/carousel';
import Features from './features';
import TopicSlider from './topic-slider';
import Faq from './faq';
import Content from './content';
import Cards from './cards';
import ContentHeadline from './headline';

export function Wrapper(element) {
  return (
    <div
      className="max-w-7xl mx-auto w-full px-6 py-6 lg:py-4 xl:px-0"
    >
      {element}
    </div>
  );
}

export function RenderElements({ elements, page }) {
  const render = [];

  if (elements) {
    elements.forEach((i) => {
      switch (i.type) {
        case 'accordion':
          render.push({
            wrapped: true,
            content: <Faq item={i} />,
          });
          break;
        case 'header':
          render.push({
            wrapped: true,
            content: (
              <ContentHeadline
                tag={i.content.headerLayout || 2}
                before={i.content.prefix}
                after={i.content.suffix}
              >
                {i.content.header}
              </ContentHeadline>
            ),
          });
          break;
        case 'text':
        case 'textpic':
          render.push({
            wrapped: false,
            content: <Content elements={elements} item={i} page={page} />,
          });
          break;
        case 'card_group':
          if (i.appearance.frameLayout === 'cards-features') {
            render.push({
              wrapped: true,
              content: <Features items={i.content.items} />,
            });
          } else if (i.appearance.frameLayout === 'cards-topics') {
            render.push({
              wrapped: true,
              content: <TopicSlider item={i} />,
            });
          } else {
            render.push({
              wrapped: false,
              content: <Cards elements={elements} item={i} />,
            });
          }

          break;
        case 'div':
          render.push({
            wrapped: true,
            content: <div style={{ height: '50px' }} />,
          });
          break;
        case 'carousel_small':
          render.push({
            wrapped: true,
            content: <Carousel item={i} />,
          });
          break;
        case 'news_pi1':
          if (i.content.data?.settings?.action === 'list') {
            render.push({
              wrapped: true,
              content: <NewsList item={i} page={page} />,
            });
          }
          break;
        case 'headmore_cruise_slider':
        case 'headmore_offers_slider':
          render.push({
            wrapped: true,
            content: <CruiseSlider searchQueryParams={i.content.data} />,
          });
          break;
        case 'headmore_boats_slider':
          render.push({
            wrapped: true,
            content: <BoatSlider searchQueryParams={i.content.data} />,
          });
          break;
        case 'headmore_json':
          if (i.content.data?.type === 'faq_grouped') {
            render.push({
              wrapped: true,
              content: <FaqGrouped items={i.content.data.items} />,
            });
          }
          break;
        case 'menu_card_list':
          if (i.appearance.frameLayout === 'menu-cards-offer-slider') {
            render.push({
              wrapped: true,
              content: <SwiperOfferSlider contentItem={i.content.items} />,
            });
          } else {
            render.push({
              wrapped: true,
              content: <OfferList items={i.content.items} />,
            });
          }
          break;
        default:
          break;
      }
    });
  }

  return (
    <div>
      {render.map((element, k) => (
        <Element
          name={`ce-${k}`}
          key={k}
        >
          {element.wrapped ? Wrapper(element.content) : element.content}
        </Element>
      ))}
    </div>
  );
}

export default RenderElements;
