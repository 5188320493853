import Image from 'next/image';
import Button from '@/components/elements/button';
import { ChevronRightIcon } from '@heroicons/react/outline';
import ContentHeadline from './headline';
import parse from '../helper/image';

function ActionButton({ item }) {
  return item.linkTitle && (
    <div className="mt-5">
      <Button
        href={item.link.href}
      >
        <>
          <span>{item.linkTitle}</span>
          <ChevronRightIcon className="w-5 text-primary" />
        </>
      </Button>
    </div>
  );
}

function DefaultCardItem({ item, tag }) {
  const ElementTag = /^\d*$/.test(tag) ? `h${tag}` : tag;
  const image = item.image.length > 0 ? item.image[0] : null;

  return (
    <div>
      {item.header && (
        <ContentHeadline after={item.suffix} before={item.prefix} tag={item.tag}>
          {item.header}
        </ContentHeadline>
      )}

      {image && (
        <div className="relative">
          <Image
            src={parse(image.publicUrl)}
            alt={image.properties.alternative || image.properties.title}
            layout="responsive"
            objectFit="cover"
            height={500}
            title={image.properties.title}
            width={900}
          />
          <ElementTag
            className="absolute left-0 font-semibold bottom-0 bg-action rounded-tr-2xl px-7 py-3 text-2xl uppercase"
          >
            {item.header}
          </ElementTag>
        </div>
      )}

      <div className="p-4">
        <div className="prose" dangerouslySetInnerHTML={{ __html: item.bodytext }} />

        <ActionButton item={item} />
      </div>
    </div>
  );
}

function DefaultCards({ item }) {
  const tag = item.content.headerLayout || 2;

  return (
    <div
      className="max-w-7xl mx-auto w-full px-6 xl:px-0 lg:py-10 py-6"
    >
      <div className="grid md:grid-cols-3 gap-y-10 md:gap-20">
        {item.content.items.map((i, k) => (
          <DefaultCardItem item={i} tag={tag + 1} key={k} />
        ))}
      </div>

    </div>
  );
}

function MasonryCardItem({ item, tag, index }) {
  const ElementTag = /^\d*$/.test(tag) ? `h${tag}` : tag;
  const image = item.image.length > 0 ? item.image[0] : null;

  return index === 0 ? (
    <div className="grid md:grid-cols-3 md:col-span-3 relative">

      {image && (
        <div className="relative md:col-span-2">
          <Image
            src={parse(image.publicUrl, { w: 2000 })}
            alt={image.properties.alternative || image.properties.title}
            layout="responsive"
            objectFit="cover"
            height={600}
            title={image.properties.title}
            width={1000}
          />

          {item.header && (
            <div
              className="absolute flex items-center flex-col justify-center flex-wrap break-after-all text-primary w-full max-w-[200px] right-0 font-semibold bottom-0 inset-y-0 bg-action px-3 text-center py-3 text-2xl uppercase"
            >
              {item.subheader}

              <ChevronRightIcon className="h-8 mt-5" />
            </div>
          )}

        </div>
      )}

      <div className="p-6 bg-white">
        <ElementTag className="text-xl text-primary font-semibold block mb-2">
          {item.header}
        </ElementTag>
        <div className="text-base lg:leading-7 text-primary" dangerouslySetInnerHTML={{ __html: item.bodytext }} />

        <ActionButton item={item} />
      </div>
    </div>
  ) : (
    <div className=" relative">

      {item.header && (
        <div
          className="flex items-center flex-row justify-center text-primary font-semibold  bg-action px-7 py-3 text-2xl uppercase"
        >
          {item.subheader}

          <ChevronRightIcon className="h-6 ml-3" />
        </div>
      )}

      {image && (
        <div className="relative">
          <Image
            src={parse(image.publicUrl, { w: 700 })}
            alt={image.properties.alternative || image.properties.title}
            layout="responsive"
            objectFit="cover"
            height={400}
            title={image.properties.title}
            width={900}
          />

        </div>
      )}

      <div className="p-4 bg-white">
        <ElementTag className="text-xl text-primary font-semibold block mb-2">
          {item.header}
        </ElementTag>
        <div className="prose text-primary" dangerouslySetInnerHTML={{ __html: item.bodytext }} />

        <ActionButton item={item} />
      </div>
    </div>
  );
}

function MasonryCards({ item }) {
  const tag = item.content.headerLayout || 2;

  return (
    <div className="bg-gray-50">
      <div
        className="max-w-7xl mx-auto w-full px-6 xl:px-0 lg:py-10 py-6"
      >
        <div className="grid md:grid-cols-3 md:gap-5">
          {item.content.items.map((i, k) => (
            <MasonryCardItem item={i} tag={tag + 1} index={k} key={k} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default function Content({ item, elements }) {
  return (
    <div>
      {item.appearance.frameLayout === 'cards-masonry' ? (
        <MasonryCards item={item} elements={elements} />
      ) : (
        <DefaultCards item={item} />
      )}
    </div>
  );
}
