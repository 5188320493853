import { jsonLdScriptProps } from 'react-schemaorg';

import SEO from '@/components/helper/seo';
import Hero from '@/components/content/hero';
import { RenderElements } from '@/components/content';
import parse from '@/components/helper/image';
import Breadcrumbs from '@/components/globals/breadcrumbs';

export const getServerSideProps = async ({
  post, page, locale,
}) => ({
  props: {
    post,
    page,
    locale,
  },
});

export default function Post({ post, page }) {
  const { breadcrumbs } = page;
  const postData = post.content.data.detail;

  console.log(postData);

  return (
    <div>
      <SEO
        title={postData.title}
        description={postData.metaData.description}
        image={postData.photo_url}
        canonicalUrl={page.meta.canonical?.href}
      />

      <script
        {...jsonLdScriptProps(
          {
            '@context': 'https://schema.org',
            '@type': 'Article',
            headline: postData.title,
            image: postData.photo_url ? [
              parse(postData.photo_url),
            ] : [],
            datePublished: postData.created_at,
            dateModified: postData.updated_at,
            publisher: {
              '@type': 'Organization',
              name: 'CharterCheck',
              url: '/',
            },
            author: {
              '@type': 'Organization',
              name: 'CharterCheck',
              url: '/',
            },
          },
        )}
      />

      <Hero
        title={postData.title}
        teaser={postData.teaser}
        pretitle={postData.subtitle}
        photoUrl={postData.media[0]?.images?.defaultImage}
        toggle={false}
      />

      <div className="mt-3" />

      <div className="max-w-3xl mx-auto">
        <div
          className="w-full px-6 xl:px-0 mb-10"
        >
          <Breadcrumbs items={breadcrumbs} />
        </div>

        <RenderElements elements={post.content.data.contentElements} page={page} />

      </div>
    </div>
  );
}
