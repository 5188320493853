import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { fetchAPI as typo3Api } from '@/lib/typo3';

import Post, { getServerSideProps as postServerProps } from '@/components/page-types/post';
import Page, { getServerSideProps as pageServerProps } from '@/components/page-types/page';
import Error from './_error';

function isPost(page) {
  return Object.values(page.content)
    .flat(1)
    .filter((i) => i !== null)
    .find((i) => i.type === 'news_pi1' && i.content?.data?.settings?.action === 'detail');
}

export const getServerSideProps = async ({
  res,
  locale,
  params,
  query,
}) => {
  try {
    if (query?.cHash) {
      // eslint-disable-next-line no-param-reassign
      delete query.cHash;
    }
    const [page] = await Promise.all([
      await typo3Api(typeof params.slug === 'object' ? params.slug.join('/') : params.slug, query),
    ]);

    if (page.redirectUrl) {
      const redirectUrl = new URL(page.redirectUrl);
      const params = new URLSearchParams(redirectUrl.search);
      params.delete('cHash');
      redirectUrl.search = params;

      return {
        redirect: {
          permanent: true,
          destination: redirectUrl.toString(),
        },
      };
    }

    const post = isPost(page);

    console.log('isPost', post);

    if (post) {
      return postServerProps({
        post,
        page,
        locale,
        params,
        query,
        serverSideTranslations,
      });
    }

    return pageServerProps({
      page,
      locale,
      params,
      query,
      serverSideTranslations,
    });
  } catch (e) {
    res.statusCode = 404;

    return {
      props: {
        errorCode: 404,
      },
    };
  }
};

export default function RenderPage({
  post, page, errorCode, ...props
}) {
  if (errorCode) {
    return <Error statusCode={errorCode} title="Seite nicht gefunden" />;
  }

  console.log(post);

  if (post) {
    return <Post post={post} page={page} {...props} />;
  }

  return <Page page={page} {...props} />;
}
