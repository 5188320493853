import Image from 'next/image';
import { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import parse from '@/components/helper/image';

export default function Gallery({
  items = [],
}) {
  const [index, setIndex] = useState(0);
  const [current, setCurrent] = useState(items[0]);

  const goTo = (ind) => {
    if (ind < 0) {
      setIndex(items.length - 1);
      setCurrent(items[items.length - 1]);
    } else if (ind + 1 > items.length) {
      setIndex(0);
      setCurrent(items[0]);
    } else {
      setIndex(ind);
      setCurrent(items[ind]);
    }
  };

  return (
    <div className="mb-5">
      <figure className="relative">
        {items.length > 1 && (
          <>
            <div className="absolute right-5 top-3 text-3xl z-20 italic text-white drop-shadow pr-2 tracking-widest">
              {index + 1}
              /
              {items.length}
            </div>
            <ChevronLeftIcon
              className="transition-all duration-500 h-14 drop-shadow-lg absolute z-20 text-white hover:text-action left-6 top-1/2 -translate-y-1/2 cursor-pointer hover:left-4"
              onClick={() => goTo(index - 1)}
            />
            <ChevronRightIcon
              className="transition-all duration-500 h-14 drop-shadow-lg absolute z-20 text-white hover:text-action right-6 top-1/2 -translate-y-1/2 cursor-pointer hover:right-4"
              onClick={() => goTo(index + 1)}
            />
          </>
        )}
        <Image
          src={parse(current.image, { w: 1200 })}
          alt={current.alt || current.title}
          layout="responsive"
          objectFit="cover"
          height={500}
          title={current.title}
          width={900}
        />
        {current.title && (
          <figcaption
            className="w-full px-3 py-1 bg-action text-primary font-bold"
          >
            {current.title}
          </figcaption>
        )}
      </figure>
    </div>
  );
}
