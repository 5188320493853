export default function Divider({
  variant = 'simple',
}) {
  if (variant === 'simple') {
    return (
      <div className="h-0.5 my-6 mx-12 border border-gray-200" />
    );
  }

  return (
    <div>wave</div>
  );
}
