import { jsonLdScriptProps } from 'react-schemaorg';

import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import ContentHeadline from '@/components/content/headline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function FaqGrouped({ items }) {
  const json = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [],
  };

  items.forEach((g) => {
    g.items.forEach((i) => {
      json.mainEntity.push({
        '@type': 'Question',
        name: i.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: i.answer,
        },
      });
    });
  });

  return (
    <>
      <script
        {...jsonLdScriptProps(
          json,
        )}
      />

      <div className="grid grid-cols-2 gap-10">
        {items.map((g) => (
          <div key={g.name}>
            <div className="mb-14">
              <div className=" divide-y-2 divide-gray-200">
                <ContentHeadline>
                  {g.name}
                </ContentHeadline>
                <dl className="mt-6 space-y-4 divide-y divide-gray-200">
                  {g.items.map((faq) => (
                    <Disclosure as="div" key={faq.question} className="pt-4">
                      {({ open }) => (
                        <>
                          <dt className="text-lg">
                            <Disclosure.Button
                              className="text-left w-full flex justify-between items-start text-gray-400"
                            >
                              <h3 className="font-semibold text-gray-900">{faq.question}</h3>
                              <span className="ml-6 h-6 flex items-center">
                                <ChevronDownIcon
                                  className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <div className="text-base" dangerouslySetInnerHTML={{ __html: faq.answer }} />
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
