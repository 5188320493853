// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/bundle';
import { Navigation } from 'swiper';
import { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'components/helper/link';
import imageUri from 'components/helper/image';
import { fetchAPI } from 'lib/connect';
import UserIcon from '../../../public/assets/icons/anker/user.svg';
import CabinIcon from '../../../public/assets/icons/anker/bed.svg';
import LengthIcon from '../../../public/assets/icons/anker/length.svg';
import DestinationIcon from '../../../public/assets/icons/anker/port.svg';

export default function BoatSlider({ searchQueryParams }) {
  const [boats, setBoats] = useState([]);

  useEffect(() => {
    const params = searchQueryParams;
    if (searchQueryParams?.limit === undefined) {
      params.limit = 10;
    }
    fetchAPI('/boat', params)
      .then((data) => {
        setBoats(data.data);
      });
  }, [searchQueryParams]);

  return (
    <div>
      {
        boats.length > 0
        && (
          <Swiper
            slidesPerView={3}
            spaceBetween={10}
            loop
            breakpoints={{
              '@0.1': {
                slidesPerView: 1,
              },
              '@0.75': {
                slidesPerView: 2,
              },
              '@1.00': {
                slidesPerView: 3,
              },
            }}
            modules={[Navigation]}
            navigation
            className="w-full"
          >
            {boats.map((boat, k) => (
              <SwiperSlide key={k}>
                <Link href={{ pathname: `/yacht/${boat.slug}` }}>
                  <div className="text-primary cursor-pointer">
                    <h5 className="p-1 pb-0 font-bold bg-action text-center">{boat.title}</h5>
                    <Image
                      src={imageUri(boat.photo_url)}
                      layout="responsive"
                      width="400"
                      height="250"
                      className="object-cover"
                      alt={boat.title}
                      title={boat.title}
                    />
                    {boat.license === 0 && (
                      <div
                        className="-mt-7 w-3/5 relative bg-primary bg-opacity-75 text-white text-sm p-1 text-center"
                      >
                        auch
                        ohne Führerschein
                      </div>
                    )}
                    <div className="relative p-2 bg-gray-200">
                      <div className="flex">
                        <DestinationIcon className="mr-2 ml-0.5 fill-primary" width={12} />
                        <span className="line-clamp-1">{boat.basements[0].location.half}</span>
                      </div>
                      <div className="flex">
                        <div className=" basis-1/3 ">
                          <div className="flex items-center">
                            <UserIcon height={14} className="mr-1 fill-primary" />
                            <span className="font-semibold">Kojen</span>
                          </div>
                          <div className="ml-5">
                            {boat.berth.fixed}
                            (+
                            {boat.berth.variable}
                            )
                          </div>
                        </div>
                        <div className="basis-1/3">
                          <div className="flex items-center">
                            <CabinIcon height={14} className="mx-1 fill-primary" />
                            <span className="font-semibold">Kabinen</span>
                          </div>
                          <div className="ml-5">
                            {boat.cabins}
                          </div>
                        </div>
                        <div className="basis-1/3">
                          <div className="flex items-center">
                            <LengthIcon height={14} className="mx-1 fill-primary" />
                            <span className="font-semibold">Länge</span>
                          </div>
                          <div className="ml-5">
                            {boat.length}
                            &nbsp;m
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="text-right font-bold pr-2 w-1/2 bg-primary text-white relative left-1/2">
                        Schon ab
                        {' '}
                        <strong>
                          {boat.min_price}
                          &nbsp;&euro;
                        </strong>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        )
      }
    </div>
  );
}
