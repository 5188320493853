/* This example requires Tailwind CSS v2.0+ */
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid';
import Link from '@/components/helper/link';
import Head from 'next/head';

export default function PaginationAlternative({ pagination, position = 'bottom' }) {
  return (
    <>
      <Head>
        {pagination.prev && (
          <link rel="prev" href={pagination.prev} />
        )}
        {pagination.next && (
          <link rel="next" href={pagination.next} />
        )}
      </Head>
      <nav
        className={`${position === 'bottom' ? 'border-t mt-5' : 'border-b mb-5'} border-gray-200 px-4 flex items-center justify-between sm:px-0`}
      >
        <div className="-mt-1 w-0 flex-1 flex">
          <Link href={pagination.prev || pagination.first}>
            <a
              className={`${position === 'bottom' ? 'border-t pt-4' : 'border-b pb-4'} border-transparent pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300`}
            >
              <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              zurück
            </a>
          </Link>
        </div>
        <div className={`${position === 'bottom' ? 'md:-mt-px' : 'md:-mb-px'} hidden md:flex`}>
          {pagination.pages.map((i) => (
            <a
              key={i.page}
              href={i.link}
              className={`${position === 'bottom' ? 'border-t pt-4' : 'border-b pb-4'} px-4 inline-flex items-center text-md font-medium ${i.current ? 'border-primary-500 text-primary-600' : 'text-gray-500 hover:text-gray-700'}`}
            >
              {i.page}
            </a>
          ))}
        </div>
        <div className="-mt-px w-0 flex-1 flex justify-end">
          <Link href={pagination.next || pagination.last}>
            <a
              className={`${position === 'bottom' ? 'border-t pt-4' : 'border-b pb-4'} border-transparent pl-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300`}
            >
              weiter
              <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            </a>
          </Link>
        </div>
      </nav>
    </>
  );
}
