import SEO from '@/components/helper/seo';
import Button from '@/components/elements/button';
import Image from 'next/image';
import Link from '@/components/helper/link';

export default function UserErrorPage({ statusCode, title = 'Seite nicht gefunden' }) {
  return (
    <>
      <SEO title={`${statusCode || 'Ups!'} - ${title}`} />
      <div className="max-w-7xl mx-auto w-full px-6 py-6 lg:py-4 xl:px-0 relative overflow-hidden">
        <div className="grid md:grid-cols-2 min-h-screen">
          <div className="flex flex-col items-start justify-center relative">

            <h1 className="my-2 font-bold text-4xl">
              Es ist ein Fehler aufgetreten - bitte entschuldigen Sie.
            </h1>
            <p className="my-2 text-gray-800 mb-20 text-lg">
              Sie können den Vorgang wiederholen oder unser
              {' '}
              <Link
                href="/kontakt"
              >
                <a className="underline">Kontaktformular</a>
              </Link>
              {' '}
              nutzen.
            </p>
            <Button
              href="/"
              variant="primary"
            >
              Zur Startseite
            </Button>

            <p
              style={{ fontSize: '20rem' }}
              className="font-bold text-primary-700 pointer-events-none opacity-10 absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2"
            >
              {statusCode}
            </p>

          </div>
          <div className="flex-col items-center justify-center hidden md:flex">
            <Image src="/images/Group.png" width={300} alt="Broke" height={200} objectFit="contain" />
          </div>
        </div>
      </div>
    </>
  );
}
