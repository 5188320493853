import { ChevronRightIcon } from '@heroicons/react/outline';
import Slider from '../elements/slider';
import ContentHeadline from './headline';
import Link from '../helper/link';

export default function TopicSlider({ item }) {
  return (
    <section className="pt-4 pb-4">
      <header>
        <ContentHeadline
          tag={item.content.headerLayout || 2}
          after={item.content.suffix}
          before={item.content.prefix}
          className="mb-2"
        >
          {item.content.header}
        </ContentHeadline>
      </header>
      <Slider
        items={item.content.items.map((item) => (
          <Link
            href={item.link.href}
            key={item.header}
          >
            <a
              className="group overflow-hidden w-slider-item lg:w-slider-item-lg bg-primary-300 text-white flex px-8 pt-8 pb-0 h-full cursor-pointer"
            >
              <div
                className="flex flex-col"
              >
                <h5 className="text-lg font-bold uppercase leading-5 text-action">
                  {item.header}
                </h5>
                <div className="py-4 h-full" dangerouslySetInnerHTML={{ __html: item.bodytext }} />

                <div className="block">
                  <div
                    className="transition-all duration-300 bg-action text-primary px-3 py-2 w-2/3 flex items-center justify-center font-semibold text-sm uppercase group-hover:bg-action-400"
                  >
                    <ChevronRightIcon className="h-3 mr-2" />
                    {' '}
                    {item.linkTitle}
                  </div>
                </div>
              </div>
            </a>
          </Link>
        ))}
      />

    </section>
  );
}
