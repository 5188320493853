import Button from '@/components/elements/button';
import { ChevronRightIcon } from '@heroicons/react/outline';
import TableOfContents from './toc';
import OfferItem from './items/offer';
import Divider from './divider';
import FeatureCard from './items/feature-card';

import VergleichsAngebot from '../../../public/assets/icons/Vergleichangebot.svg';
import ContentHeadline from './headline';
import Gallery from './gallery';
import parse from '../helper/image';
import Banner from './banner';
import Hero from './hero';

function buildGallery(galleryObject = {}) {
  const gallery = [];

  const rows = Array.isArray(galleryObject.rows) ? galleryObject.rows : Object.values(galleryObject.rows || {});

  Object.values(rows).forEach((i) => {
    Object.values(i.columns).flat(1).forEach((i) => {
      gallery.push({
        image: parse(i.publicUrl),
        title: i.properties.title,
        alt: i.properties.alternative,
      });
    });
  });

  return gallery;
}

function ActionButton({ item }) {
  return item.headerLink && (
    <div className="mt-5">
      <Button
        width="w-auto"
        href={item.headerLink.href}
      >
        <>
          <span>{item.headerLink.title || 'Mehr zum Thema'}</span>
          <ChevronRightIcon className="w-5 text-primary" />
        </>
      </Button>
    </div>
  );
}

function generateToC(elements) {
  const list = [];

  elements.forEach((i, k) => {
    if (k === 0) {
      return;
    }

    if (i.type === 'header') {
      list.push({
        title: [i.content.prefix, i.content.header, i.content.suffix].join(' '),
        id: `ce-${k}`,
      });
    }

    if (i.type === 'accordion') {
      list.push({
        title: i.content.header,
        id: `ce-${k}`,
      });
    }

    if (i.type === 'text' || i.type === 'textpic') {
      list.push({
        title: [i.content.prefix, i.content.header, i.content.suffix].join(' '),
        id: `ce-${k}`,
      });
    }

    if (i.type === 'cards') {
      i.content.items.forEach((card) => {
        list.push({
          title: [card.prefix, card.header, card.suffix].join(' '),
          id: `ce-${k}`,
        });
      });
    }
  });

  return list.filter((i) => i.title.trim().length > 0);
}

function BannerContentCol({ item, col = 1 }) {
  return (
    <Banner
      cols={col}
      item={item}
    />
  );
}

function HeroContent({ item }) {
  return (
    <Hero
      pretitle={item.content.subtitle}
      title={item.content.header}
      teaser={item.content.teaser}
      href={item.content.headerLink}
      label={item.content.headerLabel}
      photoUrl={item.content.thumbnail}
      toggle={false}
      after={item.content.suffix}
      before={item.content.prefix}
    />
  );
}

function DefaultContent({ item }) {
  const gallery = buildGallery(item.content.gallery || {});

  return (
    <div
      className="max-w-7xl mx-auto w-full px-6 xl:px-0 lg:py-10 py-6"
    >
      {item.content.header && (
        <ContentHeadline
          after={item.content.suffix}
          before={item.content.prefix}
          tag={item.content.headerLayout || 2}
        >
          {item.content.header}
        </ContentHeadline>
      )}

      {gallery.length > 0 && (<Gallery items={gallery} />)}

      <div className="text-lg mb-4 text-primary" dangerouslySetInnerHTML={{ __html: item.content.teaser }} />
      <div
        className="prose lg:columns-2"
        dangerouslySetInnerHTML={{ __html: item.content.bodytext }}
      />

      <ActionButton item={item.content} />
    </div>
  );
}

function WideContent({ item }) {
  return (
    <div className="bg-gray-100">
      <div
        className="max-w-5xl mx-auto w-full px-6 xl:px-0 lg:py-10 py-6 text-center"
      >
        {item.content.header && (
          <ContentHeadline
            after={item.content.suffix}
            before={item.content.prefix}
            tag={item.content.headerLayout || 2}
            line={false}
          >
            {item.content.header}
          </ContentHeadline>
        )}

        <div className="text-lg mb-4 text-primary" dangerouslySetInnerHTML={{ __html: item.content.teaser }} />
        <div className="prose" dangerouslySetInnerHTML={{ __html: item.content.bodytext }} />

        <ActionButton item={item.content} />
      </div>
    </div>
  );
}

function LeftRightContent({ position, item }) {
  const gallery = buildGallery(item.content.gallery || {});

  if (item.appearance.backgroundImage && item.appearance.backgroundImage.length) {
    const [image] = item.appearance.backgroundImage;

    gallery.unshift({
      image: parse(image.publicUrl),
      alt: image.properties.alternative,
      title: image.properties.title,
    });
  }

  return (
    <div
      className="max-w-7xl mx-auto w-full px-6 xl:px-0 lg:py-10 py-6 grid md:grid-cols-5 gap-10"
    >

      <div className={position === 'left' ? 'order-1 md:col-span-2' : 'order-2 md:col-span-2'}>
        {gallery.length > 0 && (<Gallery items={gallery} />)}
      </div>
      <div className={position === 'left' ? 'order-2 md:col-span-3' : 'order-1 md:col-span-3'}>
        {item.content.header && (
          <ContentHeadline
            after={item.content.suffix}
            before={item.content.prefix}
            tag={item.content.headerLayout || 2}
          >
            {item.content.header}
          </ContentHeadline>
        )}

        <div className="prose-lg mb-4 text-primary" dangerouslySetInnerHTML={{ __html: item.content.teaser }} />
        <div className="prose" dangerouslySetInnerHTML={{ __html: item.content.bodytext }} />

        <ActionButton item={item.content} />
      </div>
    </div>
  );
}

function getFilterString(filter) {
  let string = '/yachtcharter?sort=pc';
  if (filter.country) {
    string += `&country=${filter.country}`;
  }
  if (filter.sd) {
    string += `&sd=${filter.sd}`;
  }
  if (filter.region_id) {
    string += `&region_id=${filter.region_id}`;
  }
  if (filter.location_id) {
    string += `&location_id=${filter.location_id}`;
  }
  return string;
}

function LandingPageContent({ item, elements, page }) {
  const list2 = generateToC(elements);
  const gallery = buildGallery(item.content.gallery || {});
  const filter = getFilterString(page.filter);

  let picture = 'https://assets.5-anker.com/fileadmin/br24de/uploads/regionen/mueritz/seenlandschaft.jpg';
  if (gallery.length !== 0) {
    picture = (gallery[Math.floor(Math.random() * gallery.length)]).image;
  }
  return (
    <div
      className="max-w-7xl mx-auto w-full px-6 xl:px-0 lg:py-10 py-6 grid md:grid-cols-3 grid-cols-1 lg:gap-8 gap-5"
    >
      <div className="col-span-2">
        <div className="grid grid-cols-3">
          <div className="col-span-3 md:col-span-2">
            {item.content.header && (
              <ContentHeadline
                after={item.content.suffix}
                before={item.content.prefix}
                tag={item.content.headerLayout || 2}
              >
                {item.content.header}
              </ContentHeadline>
            )}
          </div>
        </div>
        <div className="prose-lg mb-4 text-primary" dangerouslySetInnerHTML={{ __html: item.content.teaser }} />
        {gallery.length > 0 && (<Gallery items={gallery} />)}
        <div className="prose lg:columns-2" dangerouslySetInnerHTML={{ __html: item.content.bodytext }} />

        <ActionButton item={item.content} />
      </div>
      <div>
        <TableOfContents items={list2} />

        <div className="h-12" />
        <OfferItem
          image={picture}
          title="Angebote"
          price="1549 €"
          description="Diverse Sonderangebote mit hohen Rabatten"
          href={filter}
        />

        <Divider />
        <FeatureCard icon={VergleichsAngebot} title="Individuelles Angebot jetzt anfordern" href="/kontakt" />
      </div>
    </div>
  );
}

export default function Content({ item, elements, page }) {
  if (item.appearance.frameLayout === 'toc') return <LandingPageContent item={item} elements={elements} page={page} />;
  if (item.appearance.frameLayout === 'stripe-1col') return <BannerContentCol item={item} />;
  if (item.appearance.frameLayout === 'stripe-2col') return <BannerContentCol col={2} item={item} />;
  if (item.appearance.frameLayout === 'hero') return <HeroContent item={item} />;
  if (item.appearance.frameLayout === 'wide') return <WideContent item={item} />;

  if (item.content.headerPosition === 'center') return <WideContent item={item} />;

  if (item.content.gallery?.position.horizontal === 'left') {
    return (
      <LeftRightContent
        item={item}
        position="left"
      />
    );
  }

  if (item.content.gallery?.position.horizontal === '' || item.content.gallery?.position.horizontal === 'right') {
    return (
      <LeftRightContent
        item={item}
        position="right"
      />
    );
  }

  return <DefaultContent item={item} />;
}
