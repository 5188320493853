import Link from '../../helper/link';

export default function FeatureCard({
  title, icon, href,
}) {
  const IconImage = icon;

  return (
    <Link href={href}>
      <a className="grid grid-cols-2 group" title={title}>
        <div className="bg-action text-primary px-4 py-6">
          <IconImage className="w-full" />
        </div>
        <div
          className="bg-primary text-white text-xl font-bold flex flex-col items-center px-5 py-6 text-center uppercase"
        >
          <div>{title}</div>
          <svg
            fill="currentColor"
            className="transition-all duration-500 group-hover:text-action h-22 bi bi-arrow-right mt-3 group-hover:translate-x-3"
            viewBox="0 0 16 16"
          >
            <path
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
            />
          </svg>
        </div>
      </a>
    </Link>
  );
}
