import Image from 'next/image';
import Link from '../../helper/link';

export default function OfferItem({
  title, description, price, prefix = 'ab', image, href,
}) {
  return (
    <Link href={href}>
      <a className="transition-all duration-200 block relative h-60 group" title={title} target="_blank">
        <Image
          src={image}
          alt={title}
          title={title}
          layout="fill"
          objectFit="cover"
        />
        <div
          className="transition bg-action text-primary absolute inset-y-0 right-0 left-[50%] md:left-[55%] p-3 md:p-5 flex flex-col group-hover:bg-primary-300 group-hover:text-action"
        >
          <div className="text-xl uppercase font-bold mb-3">{title}</div>
          <p className="text-base">{description}</p>
          {price !== null
            && (
              <>
                <p className="mt-auto text-sm">{prefix}</p>
                <p className="text-4xl font-bold">{price}</p>
              </>
            )}
        </div>
      </a>
    </Link>
  );
}
